@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes slide-up {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
    display: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 0.6;
    visibility: visible;
  }
}
@keyframes fade-out {
  from {
    opacity: 0.6;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes cookie-directive-close-options {
  0% {
    max-height: 282px;
  }
  100% {
    max-height: 0px;
  }
}
[data-ez-open=true] {
  display: block;
}

[data-ez-open=false] {
  display: none;
}

[data-ez-open=true][data-ez-animation=slide-down] {
  -webkit-animation-name: slide-down;
  -webkit-animation-duration: 1s;
  -moz-animation-name: slide-down;
  -moz-animation-duration: 1s;
  -ms-animation-name: slide-down;
  -ms-animation-duration: 1s;
  animation-name: slide-down;
  animation-duration: 1s;
}

[data-ez-open=true][data-ez-animation=fade-in] {
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 1s;
  -moz-animation-name: fade-in;
  -moz-animation-duration: 1s;
  -ms-animation-name: fade-in;
  -ms-animation-duration: 1s;
  animation-name: fade-in;
  animation-duration: 1s;
}

[data-ez-open=false][data-ez-animation=slide-up] {
  -webkit-animation-name: slide-up;
  -webkit-animation-duration: 1s;
  -moz-animation-name: slide-up;
  -moz-animation-duration: 1s;
  -ms-animation-name: slide-up;
  -ms-animation-duration: 1s;
  animation-name: slide-up;
  animation-duration: 1s;
  display: block;
}

[data-ez-open=false][data-ez-animation=fade-out] {
  -webkit-animation-name: fade-out;
  -webkit-animation-duration: 1s;
  -moz-animation-name: fade-out;
  -moz-animation-duration: 1s;
  -ms-animation-name: fade-out;
  -ms-animation-duration: 1s;
  animation-name: fade-out;
  animation-duration: 1s;
  display: block;
}

button:focus {
  outline: 0;
}

[data-ez-overlay] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: black;
  z-index: 1000000;
}

[data-ez-overlay]:hover {
  cursor: pointer;
}

[data-ez-overlay][data-ez-open=true] {
  opacity: 0.6;
}

[data-ez-overlay][data-ez-open=false] {
  opacity: 0;
  z-index: -1;
}

.ez-modules .row {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ez-modules .row .column,
.ez-modules .row .columns {
  width: 100%;
  float: left;
}
.ez-modules .row .small-1 {
  width: 8.33333333%;
}
.ez-modules .row .small-2 {
  width: 16.66666666%;
}
.ez-modules .row .small-3 {
  width: 25%;
}
.ez-modules .row .small-4 {
  width: 33.33333333%;
}
.ez-modules .row .small-5 {
  width: 41.66666666%;
}
.ez-modules .row .small-6 {
  width: 50%;
}
.ez-modules .row .small-7 {
  width: 58.33333333%;
}
.ez-modules .row .small-8 {
  width: 66.66666666%;
}
.ez-modules .row .small-9 {
  width: 75%;
}
.ez-modules .row .small-10 {
  width: 83.33333333%;
}
.ez-modules .row .small-11 {
  width: 91.66666666%;
}
.ez-modules .row .small-12 {
  width: 100%;
}
.ez-modules .row:before,
.ez-modules .row:after {
  display: table;
  content: " ";
}
.ez-modules .row:after {
  clear: both;
}
.ez-modules .text-center {
  text-align: center;
}
.ez-modules .text-left {
  text-align: left;
}
.ez-modules .text-right {
  text-align: right;
}

.ez-modules .row {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ez-modules .row .column,
.ez-modules .row .columns {
  width: 100%;
  float: left;
}
.ez-modules .row .small-1 {
  width: 8.33333333%;
}
.ez-modules .row .small-2 {
  width: 16.66666666%;
}
.ez-modules .row .small-3 {
  width: 25%;
}
.ez-modules .row .small-4 {
  width: 33.33333333%;
}
.ez-modules .row .small-5 {
  width: 41.66666666%;
}
.ez-modules .row .small-6 {
  width: 50%;
}
.ez-modules .row .small-7 {
  width: 58.33333333%;
}
.ez-modules .row .small-8 {
  width: 66.66666666%;
}
.ez-modules .row .small-9 {
  width: 75%;
}
.ez-modules .row .small-10 {
  width: 83.33333333%;
}
.ez-modules .row .small-11 {
  width: 91.66666666%;
}
.ez-modules .row .small-12 {
  width: 100%;
}
.ez-modules .row:before,
.ez-modules .row:after {
  display: table;
  content: " ";
}
.ez-modules .row:after {
  clear: both;
}
.ez-modules .text-center {
  text-align: center;
}
.ez-modules .text-left {
  text-align: left;
}
.ez-modules .text-right {
  text-align: right;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid=true] .container {
  border: 0.5px solid #28a745 !important;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid=false] .container {
  border: 0.5px solid #dc3545 !important;
}

[data-ez-module-formvalidator] .form_item .feedback.invalid {
  display: none;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid=false] .feedback.invalid {
  display: block;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid] .feedback.invalid {
  color: #dc3545;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid=false] .icon.valid-status:before {
  content: "\f00d";
  color: #dc3545;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid=true] .icon.valid-status:before {
  content: "\f00c";
  color: #28a745;
}

[data-ez-module-passwordchecker][data-ez-repeated-chars=true] .helper .helper-item.repeated-chars,
[data-ez-module-passwordchecker][data-ez-min-length=true] .helper .helper-item.min-length,
[data-ez-module-passwordchecker][data-ez-max-length=true] .helper .helper-item.max-length {
  color: #28a745;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid=true] .icon.valid-status:before {
  color: #28a745;
}

[data-ez-module-passwordchecker] .helper .helper-item .icon.valid-status:before {
  content: "\f00d";
  color: #dc3545;
}

[data-ez-module-passwordchecker][data-ez-repeated-chars=false] .helper .helper-item.repeated-chars,
[data-ez-module-passwordchecker][data-ez-min-length=false] .helper .helper-item.min-length,
[data-ez-module-passwordchecker][data-ez-max-length=false] .helper .helper-item.max-length {
  color: #dc3545;
}

[data-ez-module-passwordchecker][data-ez-repeated-chars=true] .helper .helper-item.repeated-chars .icon.valid-status:before,
[data-ez-module-passwordchecker][data-ez-max-length=true] .helper .helper-item.max-length .icon.valid-status:before,
[data-ez-module-passwordchecker][data-ez-min-length=true] .helper .helper-item.min-length .icon.valid-status:before {
  color: #28a745;
  content: "\f00c";
}

[data-ez-module-formvalidator] .form_item[data-ez-valid=false] .icon.valid-status:before {
  content: "\f00d";
  color: #dc3545;
}

[data-ez-module-passwordchecker] input[data-ez-input]:focus + .helper {
  display: block;
}

.tab_public_element {
  position: relative;
  /*
  	margin-top: 5px;
  	padding: 0px 1.4em;
  	border: 1px solid #bcbbbb;
  	border-radius: 4px;
  */
}
.tab_public_element .headline {
  /*
  		padding: 5px 0px;
  */
}
.tab_public_element .headline span {
  position: relative;
  display: inline-block;
  width: 100%;
  /*
  			color: $primary-background-color;
  			font-size: 18px;
  */
}
.tab_public_element .headline span:hover,
.tab_public_element .headline span .icon_plus:hover,
.tab_public_element .headline span .icon_minus:hover {
  cursor: pointer;
}
.tab_public_element .headline span .icon_plus,
.tab_public_element .headline span .icon_minus {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0px;
  right: 0px;
}
.tab_public_element .headline span .icon_plus svg,
.tab_public_element .headline span .icon_minus svg {
  max-height: 30px;
  max-width: 30px;
}
.tab_public_element .headline span[is-open=true] .icon_plus {
  display: none;
}
.tab_public_element .headline span[is-open=true] .icon_minus {
  display: initial;
}
.tab_public_element .headline span[is-open=false] .icon_plus {
  display: initial;
}
.tab_public_element .headline span[is-open=false] .icon_minus {
  display: none;
}
.tab_public_element .content {
  border-top: 1px solid lightgray;
  padding-top: 5px;
}

.svg_icon {
  fill: gray;
}

.svg_icon_background {
  fill: white;
}

.scroll_bar {
  height: 2px;
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  z-index: 9999;
}
.scroll_bar #scroll_indicator {
  height: 100%;
  width: 0%;
  background: red;
}

#to_top {
  transition: 0.5s ease-in-out;
  position: fixed;
  right: 10px;
  bottom: 4px;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#to_top img {
  max-width: 90px;
  max-height: 60px;
}
#to_top i {
  font-size: 24px;
  padding: 10px;
  color: #fff;
}
#to_top img:hover, #to_top i:hover {
  cursor: pointer;
}