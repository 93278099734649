@mixin icon (){

	.icon {
		padding: 5px;

		background-color: black;
		color: white;

		transition: transform 0.2s ease-in-out;
	}

	.icon:hover{
		cursor: pointer;
		transform: scale( 1.1 );
	}
}

@mixin flex (){
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flex-vertical-align {
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	align-items: center;
}

@mixin play-animation ( $name, $duration ){
	-webkit-animation-name: $name;
	-webkit-animation-duration: $duration;
	-moz-animation-name: $name;
	-moz-animation-duration: $duration;
	-ms-animation-name: $name;
	-ms-animation-duration: $duration;
	animation-name: $name;
	animation-duration: $duration;
}

@mixin overlay-position ( $width ){
	width: $width;
	left: calc( 50% - ( #{$width} / 2 ) );
}
